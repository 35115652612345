import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  TOKENS as TOKENS_ANALYTICS,
  TrackingService,
} from '@trustedshops/tswp-core-analytics';
import {
  FeatureBookingService,
  TOKENS as MASTERDATA_TOKENS,
} from '@trustedshops/tswp-core-masterdata';
import { B2BModalComponent } from '../controls/b2b-modal/b2b-modal.component';
import { B2BModalData } from '../models/b2b-modal-data.interface';
import { first } from 'rxjs/operators';

/**
 * @alias PostLoginService
 * @description Service to perform operations/checks post-login and before container finishes rendering
 */
@Injectable({ providedIn: 'root' })
export class PostLoginService {
  constructor(
    @Inject(MASTERDATA_TOKENS.FeatureBookingService)
    private _featureBookingService: FeatureBookingService,
    @Inject(TOKENS_ANALYTICS.TrackingService)
    private readonly _trackingService: TrackingService,
    private _dialogService: MatDialog
  ) {}

  performPostLoginOperations() {
    this._performClaimProfileCheck();
  }

  /**
   * @description Method to perform post-login check related to claim-profile scenario
   */
  private async _performClaimProfileCheck() {
    const isClaimProfileScenario =
      document?.location?.search?.includes('initLink');
    if (isClaimProfileScenario) {
      const isFreeAccount =
        await this._featureBookingService.hasAccountFeatureBooked(
          'FREE_ACCOUNT'
        );
      if (!isFreeAccount) {
        const modalData: B2BModalData = {
          title: 'error.modal.claimProfile.title',
          subtitle: 'error.modal.claimProfile.subtitle',
        };

        const dialogRef = this._dialogService.open(B2BModalComponent, {
          hasBackdrop: true,
          data: modalData,
        });
        // track modal show event after modal opens
        dialogRef
          .afterOpened()
          .pipe(first())
          .subscribe(async () => {
            await this._trackingService.trackEvent({
              eventType: 'event',
              eventParameters: [
                'PAID_ERROR',
                {
                  event_category: 'freeaccount',
                  event_action: 'errorpopupseen',
                },
              ],
            });
          });
      }
    }
  }
}
